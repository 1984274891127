// costingStockSettingReducer.js
import { getToken, returnError } from './helperFunc';
import {
    syncStaffOutletPermissionsAction,
    syncDistributorUserOutletPermissionsAction,
    syncStaffOperatorControllerAction,
    syncDistributorUserOperatorControllerAction,
    killSwitchAction,
    killCountAction
} from './requestEndpoints';

const SYNC_DATA_1_SUCCESS = 'SYNC_DATA_1_SUCCESS';
const SYNC_DATA_1_FAIL = 'SYNC_DATA_1_FAIL';

const SYNC_DATA_2_SUCCESS = 'SYNC_DATA_2_SUCCESS';
const SYNC_DATA_2_FAIL = 'SYNC_DATA_2_FAIL';

const SYNC_DATA_3_SUCCESS = 'SYNC_DATA_3_SUCCESS';
const SYNC_DATA_3_FAIL = 'SYNC_DATA_3_FAIL';

const SYNC_DATA_4_SUCCESS = 'SYNC_DATA_4_SUCCESS';
const SYNC_DATA_4_FAIL = 'SYNC_DATA_4_FAIL';

const CLEAR_SYNC_DATA_ERROR = 'CLEAR_SYNC_DATA_ERROR';
const SET_SYNC_DATA_LOADING = 'SET_SYNC_DATA_LOADING'

const KILL_SWITCH_SUCCESS = 'KILL_SWITCH_SUCCESS';
const KILL_SWITCH_FAILED = 'KILL_SWITCH_FAILED';

const GET_KILL_COUNT_SUCCESS = 'GET_KILL_COUNT_SUCCESS';
const GET_KILL_COUNT_FAILED = 'GET_KILL_COUNT_FAILED';

const CLEAR_KILL_SWITCH = 'CLEAR_KILL_SWITCH';
const SET_SWITCH_LOADING = 'SET_SWITCH_LOADING'


export const syncStaffOutletPermissions = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SYNC_DATA_ERROR });
        dispatch({ type: SET_SYNC_DATA_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : '';
        // console.log('stateData', adminReducer);

        const { data } = await syncStaffOutletPermissionsAction(token);

        dispatch({ type: SYNC_DATA_1_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SYNC_DATA_1_FAIL, payload: returnError(error) });
    }
};

export const syncDistributorUserOutletPermissions = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SYNC_DATA_ERROR });
        dispatch({ type: SET_SYNC_DATA_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : '';
        // console.log('stateData', adminReducer);

        const { data } = await syncDistributorUserOutletPermissionsAction(token);

        dispatch({ type: SYNC_DATA_2_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SYNC_DATA_2_FAIL, payload: returnError(error) });
    }
};

export const syncStaffOperatorController = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SYNC_DATA_ERROR });
        dispatch({ type: SET_SYNC_DATA_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : '';
        // console.log('stateData', adminReducer);

        const { data } = await syncStaffOperatorControllerAction(token);

        dispatch({ type: SYNC_DATA_3_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SYNC_DATA_3_FAIL, payload: returnError(error) });
    }
};

export const syncDistributorUserOperatorController = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SYNC_DATA_ERROR });
        dispatch({ type: SET_SYNC_DATA_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : '';
        // console.log('stateData', adminReducer);

        const { data } = await syncDistributorUserOperatorControllerAction(token);

        dispatch({ type: SYNC_DATA_4_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SYNC_DATA_4_FAIL, payload: returnError(error) });
    }
};

export const killSwitch = (status, securityCode) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_SWITCH_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : "";

        const { data } = await killSwitchAction(token, status, securityCode); // Pass securityCode

        dispatch({ type: KILL_SWITCH_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: KILL_SWITCH_FAILED, payload: returnError(error) });
    }
};


export const killCounts = (status) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_SWITCH_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : "";

        const { data } = await killCountAction(token, status);

        dispatch({ type: GET_KILL_COUNT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_KILL_COUNT_FAILED, payload: returnError(error) });
    }
};



const initialState = {
    language: 'en',
    loading: false,
    syncDone1: null,
    syncDone2: null,
    syncDone3: null,
    syncDone4: null,
    kill: null,
    killCount: null,
};

export const syncDataManagementReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_SYNC_DATA_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_SYNC_DATA_ERROR:
            return {
                ...state,
                error: null,
            };

        case SYNC_DATA_1_SUCCESS:
            return {
                ...state,
                syncDone1: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case SYNC_DATA_1_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SYNC_DATA_2_SUCCESS:
            return {
                ...state,
                syncDone2: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case SYNC_DATA_2_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SYNC_DATA_3_SUCCESS:
            return {
                ...state,
                syncDone3: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case SYNC_DATA_3_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SYNC_DATA_4_SUCCESS:
            return {
                ...state,
                syncDone4: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case SYNC_DATA_4_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        case KILL_SWITCH_SUCCESS:
            return {
                ...state,
                kill: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case KILL_SWITCH_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_KILL_COUNT_SUCCESS:
            return {
                ...state,
                killCount: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case GET_KILL_COUNT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SET_SWITCH_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_KILL_SWITCH:
            return {
                ...state,
                error: null,
            };
    

        default:
            return state;
    }
};
